
html{
  background-color: var(--color-black);
}

#conteiner-projetos{
  background-color: var(--color-black);
  margin-top: 8%;

}

#img-coordenador img{
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #fff;
  margin-left: 12%;
}

.text-coordenador {
  margin-top: 10px;
  font-weight: bold;
  font-size: 14px;
margin-bottom: 2px;
  color:#ed7947;
  text-align: center;
 
}
.nome-coordenador {
  text-align: center;
  color: white;
  font-size: 14px;
}

.nome-professor{
color: white;
margin-bottom: 40px;
}
.nome-projeto{
  color: var(--color-coral);
}

#col-projetos img {
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #fff;
  width: 80px;
  height: 80px;
  margin-left: 26%;
 
}

#col-projetos{
  transition: transform 0.9s ;
}
#col-projetos:hover{
  
  transform: scale(1.5);
  transition: transform 0.6s ease-in-out; 
}


#col-projetos h3{
  margin-top: 10px;
  font-size: 14px;

}
#col-projetos a{
 text-decoration: none;
 text-align: center;
 margin-left: 0;
 

  }
  .voltar-projetos-menu{
    color: white;
    display: flex;
    justify-content: space-between;
    margin-top: 110px;
    margin-left: 20%;
    position: absolute;
    font-size: 20px;
  }
  .voltar-projetos-menu a{
    color: white;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    #col-projetos img {
      margin-left: 32%;
      margin-top: 30px;
   
    }
    #conteiner-projetos {
   margin-top: 18%;
    }
 
    #col-projetos:hover{
      transform: scale(1.4);
    }
    
#img-coordenador img{
 margin-top: 30px;
  margin-left: 20%;
}

.voltar-projetos-menu{

  margin-left: 10%;

}
  }