



#edit-alunos{

    background-color: white;
    font-weight: bold;
  }
  #edit-alunos h3{
  
   background-color: var(--color-form);
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  }