#pesquisa-chamada {
  background-color: white;
  border-radius: 10px;
}
#LabelDataPresenca {
  color: white;
}
#pesquisa-chamada label {
  font-size: 14px;
  margin-left: 4%;

  font-weight: bolder;
}
.chebox-cfc {
  width: 30px;
  height: 30px;
}
.Painel-Menu-btn {
  width: 160px;
  height: 50px;

  margin-top: 40px;
  border-radius: 10px;
  text-align: center;

  background-color: var(--color-coral);
  color: white;
  font-weight: bold;
}
#containerData {
  margin-left: 10vw;
  gap: 30px;
}
#pesquisa-chamada h3 {
  margin-top: 10px;
  text-align: center;
  font-weight: bolder;
}

#pesquisa-chamada input {
  margin-left: 2px;
}

.img-print-lista {
  margin-left: 80%;
  font-size: 20px;
  text-align: center;

  color: white;
}

.img-print-lista p {
  color: white;
}

#btn-pesquisa-lista {
  align-self: self-end;
  justify-content: end;
  align-items: self-end;
  width: 180px;
  margin-left: 2%;
}
@media (max-width: 768px) {
  #pesquisa-chamada label {
    font-size: 12px;
  }
  .img-print-lista {
    font-size: 12px;
  }
}
