.fm-container {
  margin-top: 20px;
  max-width: 50%;
  width: auto;
  margin-left: 30%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: grid;
  gap: 16px;
  background-color: white;
  font-size: 14px;
  margin-bottom: 40px;
}

.input-group-div {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}
.input-group {
  display: grid;

  align-items: center;
}
.input-group input {
  padding: 6px;
  font-weight: bolder;
  width: 100%;
  margin-top: 2px;
}
.f-nome {
  margin-bottom: 4px;
  padding-left: 10px;
  font-weight: bold;
  margin-right: 6px;
}

.form-input {
  padding: 6px;
  box-sizing: border-box;
  width: 100px;
  margin-top: 2px;
}
.titulo-form h3 {
  text-align: center;
  background-color: rgb(205, 205, 205);
  padding: 10px;
}

.btn-add {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ed7947;
  color: #fff;
  cursor: pointer;
  width: 20%;
  margin-left: 40%;
  margin-top: 20px;
  font-size: 18px;
}

.btn-add:hover,
.btn-add:focus {
  background-color: #004080;
}

.add-voltar {
  margin-top: 40px;
  background-color: #0056b3;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  transition: background-color 0.3s ease-in-out;
}

.titulo-form h3 {
  text-align: center;
  background-color: rgb(205, 205, 205);
  padding: 10px;
}

.form-select {
  box-sizing: border-box;
  margin-top: 2px;
  width: 40%;
}
.form-select-pg {
  width: 60%;
}
.titulo-form {
  text-align: center;
}
