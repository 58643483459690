@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');

#cabeçalho{
  background-color: var( --color-darkslategray);
  height: 80px;
  
}


.usuario h4{
text-align: end;
cursor: pointer;
  color: white;
 
}
.redefinir{
  font-size: 12px!important;
  background: none;

 margin-left: 22vw;
}
.seta-user{
  font-size: 16px;
   
  }
#logo{
  width: 250px;
  margin-left: 2%;
}
#sair-header{
  color: white;
  text-align: start;
  font-size: 18px;
  cursor: pointer;
}