.btn-operacional{
width: 200px;
margin-right: 20px;
height: 100px;
border-radius: 20px;
font-size: 18px;
}
.btn-operacional:hover{
 background-color: var(--color-coral);
 color: white;
}

@media (max-width: 768px) {
    .btn-operacional{
     margin-bottom: 20px;
        }
  }