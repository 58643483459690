.valor {
color:white;
font-size: 14px;

    
}
.status-gratuito {
   color: rgb(34, 249, 34)!important;
 
   font-weight: bold;
}
 .titulo-alunos{
    color:white;
 }
 .forma-pagamento-gratuito {
   color: rgb(34, 249, 34)!important;
 
   font-weight: bold;
}
.totalMes{
   color: rgb(245, 131, 89);
   font-size: 20px;
   font-weight: bold;
}
.mr-2{
   color: white;
  background: none;
}
.status-pendente {
   color: rgb(255, 80, 80) !important;
   font-weight: bold;
}

.status-default {
   color: black;
   font-weight: normal;
}
#inserirPG{
   background-color: white;
   font-weight: bold;
}
.custom-btn {
   background-color: transparent; /* Remove a cor de fundo */
   border: none; /* Remove a borda */
   color: white; /* Usa a cor do texto pai */
   padding: 0 10px; /* Remove o padding */
 font-size: 18px;
 }
 
.container-white {
   background-color: white;
   border-radius: 5px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.pagamento{
   font-weight: bold;
}

.sub-total{
   font-size: 20px;
   color: white;
}

.form-inativos{
   color: white;
}
.form-check label{
   color: white;
}

.modal-content {
   background-color: #f8f9fa; /* Cor de fundo customizada */
   border-radius: 0.5rem; /* Bordas arredondadas */
   box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); /* Sombra suave */
}

.modal-header {
   background-color: #343a40; /* Cor de fundo do header */
   color: white; /* Cor do texto */
   border-bottom: 1px solid #dee2e6; /* Borda inferior */
}

.modal-title {
   font-weight: bold; /* Negrito no título */
}

.btn-close {
   color: white; /* Cor do botão de fechar */
   opacity: 1;
}

.modal-body {
   font-size: 16px; /* Tamanho da fonte */
   color: #495057; /* Cor do texto */
}

.modal-footer {
   border-top: 1px solid #dee2e6; /* Borda superior */
}

.btn-secondary {
   background-color: #6c757d; /* Cor do botão "Cancelar" */
   border-color: #6c757d;
}

.btn-danger {
   background-color: #dc3545; /* Cor do botão "Deletar" */
   border-color: #dc3545;
}
