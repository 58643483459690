
html, body{
  background-color: var(--color-black);

}
.container-fluid{
  background-color: var(--color-black);


}
#aprovacao{
  border: 2px!important;
  background-color:var(--color-form);
  padding: 20px;
}
#add-produto {
  margin-top: 32px!important;
}
#label-apro {
  color: white!important;
}
#relatorio-add{
  background-color: var(--color-black);

  padding-top: 5%;
}
#relatorio-add form{
  background-color: white;
  
}
#relatorio-add h3{
  background-color: var(--color-form);
  text-align: center;
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
}
#relatorio-add form label{
font-weight: bold;
}
.total input{
  font-weight: bolder;
}
#relatorio-add form button:hover{
background-color: var(--color-coral);
border: none;
  }

  @media (max-width: 768px) {
    

  }