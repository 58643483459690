/* Sidebar.css */
.sidebar {
  width: 16vw;
  height: 100vh;
  background-color: #343a40;
  color: white;
  padding: 1rem;
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: auto;
  padding-top: 100px;
}

.sidebar .nav-link {
  color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: background 0.3s;
  text-decoration: none;
}

.sidebar .nav-link:hover {
  background-color: #495057;
  border-radius: 5px;
  color: var(--color-turquoise);
  text-decoration: none;
}

.sidebar .nav-item {
  margin-bottom: 10px;
  text-decoration: none;
}

.sidebar .nav-link svg {
  margin-right: 10px;
  font-size: 1.2rem;
}

.submenu {
  margin-left: 20px;
  text-decoration: none;
}

.submenu .nav-link {
  font-size: 0.9rem;
}
