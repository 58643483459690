
html{
  background-color: var(--color-black);
}
#pesquisa-relatorio{

  background-color: white;
  border-radius: 10px;
  
}
#pesquisa-relatorio label {
font-size: 14px;
margin-left: 4%;
  
  font-weight: bolder;
}

#pesquisa-relatorio button {
align-self: self-end;
justify-content: end;
align-items: self-end;
width: 180px;
margin-left: 2%;

  }
  
  #btn-pesquisa-rel{
    margin-left: 1%;

    margin-bottom: 7px;
  }
  #btn-pesquisa-limpar{
  background-color: var(--color-coral);
  border: none;
  }
  #pesquisa-relatorio h3{
margin-top: 10px;
 text-align: center;
 font-weight: bolder;
    
  }
#voltar a{
  text-decoration: none;
  color: white;
  margin-left: 10%;

}
#voltar{
padding-top: 20px;
padding-bottom: 10px;
}
@media (max-width: 768px) {
  
 
  #pesquisa-relatorio label {
    font-size: 12px;
  
    }
    #pesquisa-relatorio input{
      font-size: 12px;
    }
    #pesquisa-relatorio button {
     font-size: 10px;
   
      width: 60px;

        }
      
}
 