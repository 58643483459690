/* Adapte os estilos conforme necessário para o seu layout específico */

.membro-container {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.conteudo-centralizado {
  text-align: center;
}


.status {
  margin-top: 40px;
  font-size: 14px;
  font-weight: bold;
  padding-top: 10px;
  text-decoration: underline;
  position: absolute;
  right: 55vw;
}

.desligamento {
  margin-top: 55px;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  color: #c70909;
  text-decoration: underline;
  position: absolute;
  right: 55vw;
}


.dados-trilho {
  background-color: var(--color-coral);
  width: 20vw;
  height: 30px;
  margin-top: 10px;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  align-content: center;
}

.dados span {
  font-weight: bold;
}

.nome-id {
  font-weight: bold;
}
.botoes-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.botao-editar,
.botao-deletar {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.botao-deletar {
  background-color: #f44336;
}
.btn-membro-detalhe-voltar {
  margin-top: 250px;
  background-color: #0056b3;
  color: white;
  border: none;

  border-radius: 5px;
  cursor: pointer;
  padding: 10px 20px;
  margin-left: 28%;
  width: 10%;
}

.pg {
  font-weight: bold;
}

.icone-confirma {
  font-size: 40px;
  color: green;
  margin-bottom: 10px;
}

.msg {
  color: black;
  margin-bottom: 15px;

  margin-top: 5px;
}

.btn-confirma {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.btn-confirma:hover {
  background-color: #0056b3;
}

.detalhe-container-endereço {
  background-color: white;
  margin-left: 50%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-right: 50%;
  display: flex;
  position: absolute;
  flex: 1;
  padding: 10px;
}
.detalhe-container {
  background-color: white;
  padding: 20px;
  margin: auto;
  margin-left: 58%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  position: absolute;
}

.conteudo-centralizado-endereço {
  text-align: center; /* Centraliza o texto */
}

.membro-container-endereço {
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
}
.text-h2 {
  font-weight: bold;
  text-decoration: underline;
}
#voltar-membro button {
  margin-left: 80%;
}
.conteudo-centralizado input[type="file"] {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f5f5f5;
  display: none;
}
#dadosMembros img {
  border-radius: 20px;
  display: block;
  height: 200px;
  width: 200px;
  margin-top: 3%;
  border: solid 5px;

  border-color: var(--color-form);
}
#dadosMembros {
  background-color: white;
  border-radius: 10px;
  border: solid 5px;
  border-color: var(--color-form);
  font-size: 14px;
}

.nome-id-membro {
  background-color: var(--color-form);
  border-radius: 10px;
  color: white;
  padding: 7px;
  text-decoration: none;
}
@media (max-width: 768px) {
  #voltar-membro button {
    margin-left: 18%;
  }
}
