#bem-vindo {
  text-align: center;
}

.title {
  color: var(--color-coral);
  font-size: 24px;
  margin-bottom: 30px;
}

.Painel-Menu {
  width: 160px;
  height: 50px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 50px;
  background-color: var(--color-coral);
  color: white;
  font-weight: bold;
}
.botoes-container {
  gap: 30px;
}
.trilho-titulo {
  font-size: 24px;
  color: white;
}
