td a{
  text-decoration: none;
  color: white;
}

html{
  background-color: var(--color-black);
}

#col-tab-alunos  {
 
 
  color: white;
}

.table-striped>tbody>tr:nth-child(odd)>td, 
.table-striped>tbody>tr:nth-child(odd)>th{
  background-color: var( --color-darkslategray);
  color: white;
}
.table-striped>tbody>tr:nth-child(even)>td, 
.table-striped>tbody>tr:nth-child(even)>th{
color: white;
  background-color: var(--color-dimgray);
}

.table-striped thead th:first-child {
 
  border-top-left-radius: 10px;
}
.table-striped>tbody>tr:nth-child(odd)>td:hover, 
.table-striped>tbody>tr:nth-child(odd)>th:hover{
  background-color: var(--color-turquoise);
  
}
.table-striped>tbody>tr:nth-child(even)>td:hover, 
.table-striped>tbody>tr:nth-child(even)>th:hover{
  background-color:var(--color-turquoise);
}


.table-striped thead th:last-child {
  border-top-right-radius: 10px;
}

.table-striped thead th{
background-color: #00D4FF;
}

.voltar-projetos-detalhes{
  color: white;
  display: flex;
  justify-content: space-between;
  margin-top: 110px;
  margin-left: 10%;
  position: absolute;
  font-size: 14px;
}
.voltar-projetos-detalhes a{
  color: white;
  text-decoration: none;
}
@media (max-width: 768px) {

  
  #col-tab-alunos table{
  font-size: 12px;
    margin-top: 30px;
}
.voltar-projetos-detalhes{
  margin-top: 130px;
  font-size: 12px;
}

}
