.dados-atendimento {
  background-color: white;
  width: 140px;
  height: 60px;
  margin-right: 20px;
  background-color: var(--color-turquoise);
  align-content: center;
  border-radius: 10px;
  font-size: 14px;
}

.dados-atendimento span {
  display: block;
  text-align: center;
  font-weight: bolder;
}
.ultimos-atendimentos {
  color: white;
  font-size: 18px;
}
.titulo-grafico-atendimento {
  color: white;
  font-size: 20px;
}
.atendimento-tipo {
  background-color: var(--color-form);
  font-weight: bolder;
  padding: 5px;
  font-size: 14px;
}
.btn-atendimento {
  background-color: var(--color-coral) !important;
  width: 150px;
  height: 40px;
  margin-right: 20px;
  border-radius: 10px;
  color: white;
  margin-bottom: 20px;
  font-size: 12px;
}
.nome-atendimento li {
  font-size: 14px;
  list-style: none;
}

.select-dados-atendimento option {
  height: 24px;
  font-size: 13px;
}
.select-dados-atendimento {
  height: 240px!important;
}
.limpar-atendimento{
  margin-Top: 10px;
  background-color: #ff4d4f;
  color: white;
  position: relative;
  margin-Left:14vw;
}
.label-atendimento {
  font-weight: bold;
  margin-top: 5px;
}
.selecionados {
  margin-top: 5px;
}

/* Fundo escuro semitransparente */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Estrutura do modal */
.custom-modal {
  background: #fff;
  width: 800px;
  max-width: 90%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

/* Cabeçalho do modal */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

/* Botão de fechar */
.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

/* Corpo do modal */
.modal-body {
  margin-top: 15px;
}

/* Rodapé do modal */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
  margin-top: 10px;
}

/* Botões do modal */
.modal-footer .btn {
  padding: 8px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.btn-secondary {
  background: #ccc;
}

.btn-success {
  background: #28a745;
  color: white;
}

/* Campos do formulário */
.form-label {
  font-weight: bold;
  margin-top: 10px;
  display: block;
}

/* Seletores múltiplos */
.select-dados-atendimento {
  width: 100%;
  height: 100px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
}

/* Lista de selecionados */
.selecionados {
  font-size: 14px;
  margin-top: 5px;
  color: #555;
}

/* Estilo do input e botão de adicionar */
.d-flex {
  display: flex;
  gap: 10px;
}

/* Lista de não cadastrados */
.list-group-item {
  background: #f8f9fa;
  border: 1px solid #ddd;
  padding: 8px 12px;
  border-radius: 5px;
  margin-top: 5px;
}

.list-group-item:hover {
  background: #e9ecef;
}

/* Badge de exclusão */
.badge {
  cursor: pointer;
  padding: 3px 6px;
  border-radius: 50%;
}
