#login-redefinir{
    margin-top: 4%;
        background-color: white;
        border-radius: 10px;
        padding: 10px;
      
    }
    #login-redefinir h3{
    text-align: center;
     font-weight: bold;
    }
    #login-redefinir label{
       margin-top: 20px;
      
        }
    
        #login-redefinir span{
           font-weight: bold;
          font-size: 12px;
           
     }
         
    
    #alterar-senha button{
        margin-top: 20px;
        background-color:var(--color-black);
        border: none;
      
     }    
     #alterar-senha button:hover{
       background-color: var(--color-coral);
      
     }   
     #login-redefinir input{
     
      
     }    