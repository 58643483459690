.titulo-mes {
  color: white;
}

.dados-secretaria {
  color: var(--color-black);

  text-align: center;
  background-color: var(--color-turquoise);
  border-radius: 10px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 150px !important;
  height: 60px;
  align-content: center;
}

.dados-secretaria i {
}
.faPeopleGroup {
  font-size: 20px;
  margin-left: -30px;
  margin-right: 10px;
}
.faChildren {
  font-size: 20px;
  margin-left: -30px;
  margin-right: 10px;
}
.FaChartSimple {
  font-size: 20px;
  margin-left: -30px;
  margin-right: 10px;
}
.data-conteudo {
  display: flex;
}
.TbClockHour5 {
  margin-right: 5px;
  margin-top: -3px;
font-size: 18px;
}
.tb-aniversario {
  font-size: 14px;
}
.data-container {
  background-color: white;
  border-radius: 10px;
  padding-bottom: 10px;
}
.descricao-data {
  display: block;
  margin-left: 20px;
  font-size: 14px;
  margin-top: 5px;
}
.descricao-data {
  display: block;
  margin-left: 20px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
  color: var(--color-coral);
}
.descricao-responsavel {
  display: block;
  margin-left: 20px;
  font-size: 14px;
}
.calendario-sec{
  margin-top: -5px;
}
.descricao-responsavel-base {
  display: block;
  margin-left: 20px;
  font-size: 14px;
 
  font-weight: bold;
}
.data-mes {
  background-color: var(--color-form);
  width: 90px;
  margin-left: 10px;
  margin-top: 10px;
}
.mes-sec {
  background-color: white;
  width: 80px;
  text-align: center;
  margin-left: 5px;
  margin-top: 6px;
  font-weight: bold;
}
.dia-sec {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}
.data-mes span {
  display: block;
}
.dados-secretaria h4 {
  font-weight: bold;
  font-size: 14px;
}
.titulo-mes-evento {
  color: white;
  margin-bottom: -8px;
}
