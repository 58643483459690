.container-menu {
  transition: transform 0.6s;
  overflow: hidden;
  color: white;
  justify-items: center;
  position: relative;
  text-align: center;
}

.container-menu img {
  width: 24vw;
  height: 35vh;
  display: block;
  transition: transform 0.3s ease-in-out;
  z-index: 1;
  border-radius: 20px;
}

/* Efeito hover */
.container-menu:hover {
  transform: scale(1.1);
}

.container-menu:hover img {
  filter: brightness(0.3);
}

.container-menu h3 {
  position: relative;
  bottom: 80px;
margin-right: 1vw;

  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  z-index: 2;
  color: white;
  font-size: 1.6rem;
  font-weight: bold;
}

.container-menu:hover h3 {
  opacity: 1;
}
@media (max-width: 768px) {
  .container-menu img {
    width: 200px;
    height: 130px;
    margin-bottom: 30px;
  }
  .container-menu h3 {

    bottom: 80px;
margin-left: 3vw;
    font-size: 12px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  #col-img button {
    margin-left: 29%;
  }
}
