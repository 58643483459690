.menu-transferencia{
  margin-right: 20px;
  width: 200px;
  height: 30px;
  border-radius: 10px;
  margin-bottom: 50px;
  background-color: var(--color-coral);
  color:white;
  font-weight: bold;
  font-size: 12px;
 }
 .menu-transferencia.ativo {
  background-color: var(--color-turquoise); 
  color: var(--color-black); 
  font-weight: bold;
}
.menu-transferencia-home.ativo {
  background-color: var(--color-turquoise); 
  color: var(--color-black); 
  font-weight: bold;
}


.menu-transferencia-home{
  margin-right: 20px;
  width: 100px;
  height: 30px;
  border-radius: 10px;
  margin-bottom: 50px;
  background-color: var(--color-coral);
  color:white;
  font-size: 16px;
  font-weight: bold;
 }