.dadosMembros {
  background-color: white;
  border-radius: 20px;
}
.nome-membro {
  font-size: 24px;
  color: var(--color-form);
  font-weight: bolder;
  margin-left: 15px;
  margin-bottom: 5px;
}

.dados-pessoais {
  display: block;
  position: relative;

  margin-top: 25px;
}
.foto-de-membro {
  border-radius: 20px;
  display: block;
  height: 200px;
  width: 200px;
  margin-top: 3%;
  border: solid 5px;

  border-color: var(--color-form);
}
.dados-m span {
  font-weight: bold;
  margin-left: 10px;
  color: var(--color-form);
}
.endereço-membro {
  background-color: white;
  border-radius: 20px;
}
.status-membro {
  margin-left: 26vw;
  margin-top: -22px;
  font-size: 12px;
  font-weight: bold;
  padding-top: 10px;
  text-decoration: underline;
  position: absolute;
}
.titulo-endereço {
  background-color: var(--color-form);
  margin-top: 20px;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
}
.botoes-membros-container {
  display: flex;
  margin-top: 20px;
  font-size: 12px;
}

.botao-editar-membro {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: var(--color-turquoise);
  font-weight: bolder;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 150px;
}
.botao-editar-membro:hover {
  background-color: var(--color-coral);
}
.voltar-detalhe-membro {
  font-size: 12px !important;
  width: 120px;
  position: absolute;
  margin-left: 60vw;
}
.botao-deletar-membro {
  background-color: #f44336;
  width: 150px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 30px;
  padding: 10px 20px;
  font-weight: bolder;
}
.desligamento-membro {
margin-left: 25vw;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  color: #c70909;
  text-decoration: underline;
  position: absolute;
 
}
