.dados-fin {
  background-color: var(--color-turquoise);
  width: 220px;
  height: 50px;
  border-radius: 10px;
  margin-right: 10px;
  align-content: center;
}
.dados-fin span {
  display: block;
text-align: center;
  align-content: center;
  font-weight: bold;
  font-size: 14px;
}
.titulo-graf-fin{
  color: white;
  text-align: center;
  font-size: 16px;

}