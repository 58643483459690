


#barra-projeto{
  background-color: var(--color-darkslategray);
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
}
#barra-projeto button{
  margin-left: 5%;
  border-radius: 10px;
  border: none;
  font-size: 12px;
  font-weight: bold;
 background-color: var(--color-coral);

}
#barra-projeto button:hover {
 
 background-color: var(--color-turquoise);

}

@media (max-width: 768px) {
  #barra-projeto button{
    margin-left: 1%;
    font-size: 10px;
    padding: 5px;
  
  }

 

  
}
 