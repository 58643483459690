.tabela-calendario {
  max-width: 60vw !important;
  margin-top: 20px;
  justify-content: center !important;
  text-align: center;
  border-collapse: collapse;
  margin-left: 4vw;
  background-color: white;
}

.calendario-th th {
  height: 50px;
  background-color: rgb(14, 223, 153) !important;
  font-size: 14px;
}

.day {
  width: 14.28%;
  height: 80px;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
  border: 1px solid #ddd;
  font-size: 12px;
}
.day strong {
  text-align: center;
  display: block;
  position: absolute;
  font-size: 12px;
  margin-top: -10px;
}
.btn-esquerda {
  position: absolute;
  left: 8vw;
}
.btn-limpar {
  margin-left: 10px;
  margin-right: 10px;
}
.btn-direita {
  position: absolute;
  left: 27vw;
}
.titulo-evento {
  font-size: 18px;
}
.mes-atual {
  color: white;
}
.add-evento {
  position: absolute;

  margin-left: 10vw;
  color: white;
  border-radius: 5px;
  font-size: 12px;

  background-color: var(--color-coral);
  padding: 1px 8px;
}
td.day:hover {
  background-color: #b2dbff;
}
.seletor-calendario {
  margin-left: 30px;
  margin-right: 10px;
}
td.empty {
  background-color: #f8f9fa;
  cursor: default;
}
.input-calendar {
  margin-left: 20px;
}

.event {
  padding: 6px 4px !important;
  border-radius: 3px;
  margin-top: 20px !important;
  align-items: center;
}

.modal-dialog {
  display: flex;
  width: 30vw !important;
  align-items: center;
  justify-content: center;
  background: none !important;
  background: transparent !important;
}
.evento-detalhes h5 {
  color: white;
  font-size: 18px;
}
.evento-detalhes p {
  color: white;
  font-size: 14px;
}
.evento-detalhes {
  background-color: var(--color-coral);
  margin-top: 10px;
  border-radius: 10px;
  padding: 5px;
}
.fade {
  margin-top: 340px;
  background-color: transparent;
  position: absolute;
  margin-left: 50vw;
}

.modal-backdrop {
  background: none !important; /* Remove o fundo escuro */
}
.hoje {
  background-color: rgb(
    240,
    180,
    180
  ) !important; /* Fundo vermelho para o dia atual */
  color: white !important; /* Texto branco para melhor contraste */
  font-weight: bold;
  border-radius: 5px;
}
