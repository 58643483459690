#crianca {
  color: rgb(252, 235, 9);
}
#crianca span {
  color: rgb(255, 0, 0);
}
td .afastado a {
  background-color: rgb(255, 238, 0);
  font-weight: bold;
}
.dataNascimento {
  color: white;
}
.foto-membro {
  width: 200px;
}
@media print {
  .img-print-membro {
    display: none;
  }
  #btn-pesquisa {
    font-size: 11px;
    padding: 5px;
  }
  #botoes button {
    font-size: 14px;
    padding: 5px;
  }
  #barra-pesquisa-secretaria input {
    font-size: 10px;
  }

  #barra-pesquisa-secretaria {
    margin-left: 10px;
  }
  .img-print-membro button {
    font-size: 11px;
  }
  .table a {
    font-size: 10px;
  }
  .table {
    font-size: 10px;
  }
}

.img-print-membro {
  margin-left: 90%;
  text-align: center;
  font-size: 22px;
}
.img-print-membro button {
  background: none;
  color: white;
  font-size: 18px;
}
@media (max-width: 768px) {
  #botoes button {
    font-size: 10px;
    padding: 5px;
  }
}
