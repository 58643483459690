
  .container-fluid{
    background-color: var(--color-black);
  }
  
#conteiner-login{
    margin-top: 10%;
    background-color: var(--color-black);
}

.copy{
    color: white;
    text-align: center;
    margin-top: 5%;
    background-color: var(--color-black);
}

#login{
    background-color:white;
    border-radius: 10px;
   
}

.invalid-error-message{
color: red;
}
.row {
    background-color: var(--color-black);

}
#btn-logar{
    margin-top: 20px;
   background-color: var(--color-black);
   border: none;
}
#btn-logar:hover{
    background-color: var(--color-coral);
    border: none;
}

#olho{
    border: none;
}
#login h2{
    margin-top: 10px;
    font-weight: bold;
}
.input-group{
    font-weight: 100;
}

#loading-image{
    margin-top: 20px;
    width: 50px;
    height: 55px;
}

@media (max-width: 768px){

    #login{
        margin-top: 20px;
       margin-left: 16%;
       margin-bottom:50px;;
    }
    .img-fluid{
        width: 60%;
        height: 50%;
        margin-left: 20%;
        margin-top: 1px;
       
    }
    .row{
        margin-top: 0;
    }
    .copy{
        margin-top: 1%;
        font-size: 12px;
    }
    #conteiner-login{
        margin-top: 20%;
      
    }
}