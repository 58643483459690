


#add-alunos{

  background-color: white;
}
#add-alunos label{

 font-weight: bold;
}

#add-alunos h3{

text-align: center;
font-size: 16px;
font-weight: bold;
background-color: var(--color-form);
padding: 10px;

}


#add-alunos button:hover{
  background-color: var(--color-coral);
border: none;
  }
  
  
#voltar-alunos button{
    margin-top: 5%;
    margin-left: 47%;
    margin-bottom: 5%;
}



  
  .btn-alunos {
   
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #ed7947;
    color: #fff;
    cursor: pointer;
    width: 20%;
    margin-left: 40%;
  margin-top: 20px;
  font-size: 18px;
    
  }
  
  .btn-alunos:hover,
  .btn-alunos:focus {
    background-color: #8e492c;
  }
  

 

 
  .btn-aluno-voltar {
   
    padding:10px;
    border: none;
    border-radius: 3px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    width: 8%;
    margin-left: 47%;
    margin-top: 40px;
    margin-bottom: 20px;
    
    }
    .btn-aluno-voltar:hover,
    .btn-aluno-voltar:focus {
      background-color: #004080;
    }
    
@media (max-width: 768px) {

  #col-tab-alunos-2 table{
    font-size: 14px;
      margin-top: 30px;
  }
}