#bem-vindo {
  text-align: center;
  color: white;
}
#bem-vindo p {
  color: var(--color-coral);
}
#conteiner-inicio {
  background-color: var(--color-black);
  margin-bottom: 50px;
}

#container-img {
  transition: transform 0.6s;
  overflow: hidden;
  color: white;
  justify-items: center;
  height: 100%;
  width: 10vw;
}
#container-img-modulo {
  transition: transform 0.6s;
  overflow: hidden;
  color: white;
  justify-items: center;
  height: 100%;
  width: 15vw;
}
#container-img-modulo:hover {
  transform: scale(1.1);
}
.idade-trilho {
  color: var(--color-coral);
}
.idade-trilho-h3 {
  margin-left: 2vw;
  margin-right: 10px;
}
#img-menu {
  border-radius: 10px;
}

#container-img:hover {
  transform: scale(1.1);
}
html {
  background-color: var(--color-black);
}
h3 {
  color: white;
  text-align: center;
  font-size: 30px;

  margin-bottom: 50px;
}
.titulo-trilho h3 {
  font-size: 18px;
  color: white;
}
