.titulo-curso {
  color: white;
}

.section-trilho {
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  max-height: 150px;
  overflow-y: auto !important;

}
.sobre-trilha {
  font-size: 16px;
}
.section-curso li {
  background-color: var(--color-form);
  cursor: pointer;
  padding: 10px; /* Aumenta a área clicável */
  border-radius: 5px;
  display: flex;
  width: 150px;
  justify-content: center;
  gap: 10px;
}
.section-curso ul {
  display: flex;
  gap: 20px;
  color: white;
}
.section-curso h3 {
  color: white;
}
.escolha {
  color: white;
}
.checkbox-label {
  width: 100%; /* Faz o label ocupar toda a área do li */
  display: flex;
  align-items: center;
  cursor: pointer;
  appearance: none;
}

.custom-checkbox {
  appearance: none;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  position: relative;
}
.curso-item {
  background-color: var(--color-darkslategray);
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  color: white;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px;
}

.curso-item.active {
  background-color: var(--color-dimgray);
  appearance: none;
}

.custom-checkbox {
  appearance: none;
}

.inscrever {
  width: 140px;
  height: 40px;
  background-color: var(--color-coral);
  color: white;
  border-radius: 10px;
}
.baixar {
  width: 140px;
  height: 40px;
  background-color: var(--color-darkslategray);
  color: white;
  border-radius: 10px;
}
.menu-curso.hidden {
  display: none;
}
.menu-curso.visible {
  display: block;
}

.botoes-menu {
  gap: 20px;
}

.voltar {
  width: 160px;
  height: 40px;
}
.menu-curso {
  color: white;
  text-align: center;

}
.menu-curso p {
  color: black;
  text-align: initial;
  width: 300px;
  white-space: pre-wrap;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
  font-size: 14px!important;

}

.curso-trilha {
  color: var(--color-coral);
  font-size: 22px;
}
.botoes {
  justify-content: center; 
  align-items: center;
  gap: 20px;
  display: flex;
}
