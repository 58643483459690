.trilho {
  color: white;
}
.curso {
  color: white;
}
.labelOpcao {
  color: var(--color-coral);
}
.labelApostla {
  color: var(--color-coral);
  margin-right: 20px;
}
.curso-selecionado {
  color: white;
  font-weight: bold;
  position: absolute;
  right: 10vw;
  margin-top: 50px;
  background-color: var(--color-turquoise);
  padding: 18px;
  border-radius: 20px;
  width: 200px;
}
.btn-inscricao {
  width: 150px;
  height: 40px;
  border-radius: 10px;
  color: white;

  border: none;
  background-color: var(--color-coral);
}
.opcao-membro {
  color: white;
}
.checkbox-apostila {
  transform: scale(1.7); /* Aumenta o tamanho do checkbox */
  margin-right: 10px; /* Mantém o espaçamento ao lado do texto */
  cursor: pointer; /* Adiciona um cursor de ponteiro para interatividade */
}
.Sim {
  margin-right: 10px;
  font-size: 20px;
}
.Nao {
  margin-left: 6vw;
  margin-right: 10px;
  font-size: 20px;
}
.dados-visitante {
  font-weight: bold;
}
.form-visitante {
  background-color: white;
}
.input-sim {
  margin-left: 10px;
}
