.ganho-bg {

  color: #00ff3c!important; /* Verde Escuro para Contraste */
  font-weight: bold;
}
.total-transacao strong{
  color: #00ff3c;
}
.btn-pesquisa-ganho{
  background-color: var(--color-coral);
  color:white;
  width: 150px;
  height: 50px;
  border-radius: 10px;
  font-weight: bolder;
  margin-left: 10px;
}
.despesa-bg {

  color: #f80000!important; /* Texto Branco para Contraste */
  font-weight: bold;
}
