#bem-vindo {
  text-align: center;
  color: white;
}
#bem-vindo p {
  color: var(--color-coral);
}
#conteiner-inicio {
  background-color: var(--color-black);
  margin-bottom: 50px;
}
#tituloChamada {
  color: white;
}
.btn-linkVoltar {
  color: white !important;
  font-size: 20px !important;
  margin-left: 20vw;
  margin-top: 10px;
}
.btn-linkVoltar button {
  color: white;
  text-decoration: white;
}
.dados-Historico {
  color: white;
}
.tabela-chamada{
  font-size: 14px;
}
#container-img {
  transition: transform 0.6s;
  overflow: hidden;
  color: white;
  justify-items: center;
  height: 100%;
  width: 10vw;
}
.idade-trilho {
  color: var(--color-coral);
}
#img-menu {
  border-radius: 10px;
}

#container-img:hover {
  transform: scale(1.1);
}
html {
  background-color: var(--color-black);
}
#linha-menu h3 {
  color: white;
  text-align: center;
  font-size: 16px;

  margin-bottom: 50px;
}
.mes-chamada-cfc{
  color:white;
  position: absolute;
  font-size: 20px;
 left: 70vw;
top:30vh;

}
.titulo-chamada-historico{
  color: white;
}
@media (max-width: 768px) {
  #img-menu {
    margin-top: 20px;
  }
  #linha-menu {
    margin-top: 10px;
  }
  #container-img button {
    font-size: 10px;
    width: 100px;
    margin-right: 120px;
  }
  #container-img h3 {
    font-size: 12px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  #container-img button {
    margin-left: 29%;
  }
}
