.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.icone-confirma {
  font-size: 50px;
  color: red;
}

.msg {
  margin-top: 10px;
  font-size: 18px;
}

.btn-confirma {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.btn-cancel {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgb(18, 113, 255);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}



