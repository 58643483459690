

#relatorio-view{

  background-color: white;
}

#relatorio-view img{
  width: 300px;
  height: 120px;
 margin-left: 27%;
 margin-top: 0;
 margin-bottom: 0;
}

#imprimir button{
background: none;
color: white;
margin-left: 20%;
}


  .data-relatorio {
    font-style: italic;
    color:#ed7947; ;
  }
  
  .pergunta {
    font-weight: bold;
    background-color: cadetblue;
    padding: 8px;
  }
  
  .resposta {
    margin-top: 5px;
    margin-bottom: 40px;
   
  }

.voltar-relatorio {
  color: white;
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  margin-left: 15%;
  position: absolute;
  font-size: 20px;
}
.voltar-relatorio a{
  color: white;
}

@media (max-width: 768px) {
  
  #relatorio-view img{
    width: 150px;
    height: 60px;
   margin-left: 30%;
  
  }
  
}