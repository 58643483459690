.aprovado td a{
  font-weight: bold;
  color:#00ff3c!important; 
}

.recusado td a{
  color: #ff5050 !important;
  font-weight: bold;
}
.editar-req{
  color: var(--color-turquoise)!important;
}
#pesquisa-chamada{

  background-color: white;
  border-radius: 10px;

}
.pendente-requerimento{
  color: white;
  margin-left: 20px;
}
.aprovar-req{
  color:#00ff3c!important; 

}
.btn-voltar{
  background: none;
  color: white;
  margin-left: 20vw;
}
#pesquisa-chamada label {
  font-size: 14px;
  margin-left: 4%;
  font-weight: bolder;
  }

  #pesquisa-chamada h3{
    margin-top: 10px;
     text-align: center;
     font-weight: bolder;
        
   }

 #pesquisa-chamada input{
      margin-left:2px;
            
          }
    

.img-print-lista{
margin-left: 80%;
font-size: 20px;
text-align: center;

color: white;
}

.img-print-lista p{
  color: white;
 
}

#btn-pesquisa-lista{
  align-self: self-end;
justify-content: end;
align-items: self-end;
width: 180px;
margin-left: 2%;
}

#barra-requerimento button{
  margin-top: 20px;
  margin-left: 5%;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-weight: bold;
 background-color: var(--color-coral);

}
#barra-requerimento button:hover {
 
 background-color: var(--color-turquoise);

}
.mes-contaPagar{
  color:white;
  position: absolute;
  font-size: 20px;
  right: 20vw;
  margin-top: -60px;
}
.btn-left-conta{
  background: none;
  color: white;
}
.btn-right-conta{
  background: none;
  color: white;
}
@media (max-width: 768px) {
  #barra-projeto button{
    margin-left: 1%;
    font-size: 10px;
    padding: 5px;
  
  }

 

  
}
 

@media (max-width: 768px) {
  #pesquisa-chamada label{
   font-size: 12px;
          
        }
        .img-print-lista{
       
          font-size: 12px;
         
          }
}

















































